import { FixedFormat } from '@ethersproject/bignumber';
import { _ } from 'core-js';
import { ethers, BigNumber } from 'ethers';
import { Pixel } from 'node-avatars';
import WalletConnectProvider from "@walletconnect/web3-provider"

const RC = false

let db = window.localStorage;

let abi = [
    "function tokenURI(uint256 tokenId) public view returns (string memory)",
    "function insertCoin(string memory name) external payable",
    "function insertNote(string memory name) external payable",
    "event Transfer(address indexed src, address indexed dst, uint val)",
    "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",
    "function currentPrice(string memory name) public view returns (uint price)",
    "function currentBulkPrice(string memory name) public view returns (uint price)",
    "function claimPrize(uint tokenId, uint points) public",
    "function alreadyMinted(string memory name) external view returns (uint _count)",
    "function supplyForGame(string memory name) external view returns (uint _supply)",
    "function checkEligibility(uint tokenId, uint ranking) public view",
    "function getPrizeIdData(uint prizeId) external view returns (uint ranking, address winner, uint winningToken)"
];

const rinkebyContractAddress = "0xd7ded0292c2ece5d6d7602c8e1861b9c3755bef5"
const mainnetContractAddress = "0x7eefee2d0b0e23b7ce6b56a9ce9b62a599e6e9da"
const devContractAddress = "0x9C97089C54e58E9BB45d27E6500F898538864BE5"

const infuraId = "04ed07fd5ccd404990855a35666de4f6"
const mainnetJSONRpc = `https://mainnet.infura.io/v3/${infuraId}`
const rinkebyJSONRpc = `https://rinkeby.infura.io/v3/${infuraId}`
const devJSONRpc = "http://localhost:7545"
let rpcUrl;

const game = "snake";
const openseaCollectionURL = "https://opensea.io/collection/"
const testnetCollectionOpenseaURL = "https://testnets.opensea.io/collection/"
const collectionName = "arcadeglyphs";
const testSubdomain = "diocane";
const trophyIds = [
    "115792089237316195423570985008687907853269984665640564039457584007913129639931", 
    "115792089237316195423570985008687907853269984665640564039457584007913129639932",
    "115792089237316195423570985008687907853269984665640564039457584007913129639933",
    "115792089237316195423570985008687907853269984665640564039457584007913129639934"
]

let provider;
let contractRead;
let contractWrite;

let contractAddress;
var price = "19760000000000000";
var bulkPrice = "98800000000000000";
let openSeaBaseAddress;
let supply;

let address = () => db.getItem("address");

// actions
const connectButtonClass = "connectbutton";
const mintButtonClass = "mintbutton";
const bulkMintButtonClass = "mint5button";
const walletButtonClass = "walletbutton";
const logoutButtonClass = "logoutbutton";

// Trophies
const claimPrize1ButtonClass = "claimprize1button";
const claimPrize2ButtonClass = "claimprize2button";
const claimPrize3ButtonClass = "claimprize3button";
const claimPrize4ButtonClass = "claimprize4button";
const claimPrizeSubmitButtonClass = "claimprizesubmitbutton";
const overlayClaimingClass = "modalclaiminginprogress";
const overlayClaimingDoneClass = "modalclaimingcompleted";
const overlayClaimingFailedClass = "modalclaimingfailed";
const prizeInputTokenModalClass = "prizeinputtokenmodal";
const prizeInputTokenIdClass = "prizeinputtokenid";

// placeholders
const userTokensClass = "gamelist";

const totalSupplyTextClass = "totalsupply";
const alreadyMintedTextClass = "alreadyminted";
const hpClass = "hp";
const avatarClass = "avatar";
const walletClass = "walletid";

// Overlay
const overlayClass = "overlay";

// Minting
const overlayMintingClass = "modalmintinginprogress";
const overlayMintingDoneClass = "modalminitingcompleted";
const overlayMintingFailedClass = "modalminitingfailed";

const openseaLinkClass = "opensealink";

function isDomainProd() {
    let subdomain = window.location.hostname.split(".")[0]
    return subdomain === "arcadeglyphs" || subdomain === "www"
}

function isDomainStaging() {
    let subdomain = window.location.hostname.split(".")[0]
    return subdomain === "diocane"
}

function isRCProd() {
    return isDomainProd() && RC
}

function getMessage(error) {
    var message;
    try {
        message = error.data.message.split("revert")[1].trim().replace(/"+$/, "");
    }
    catch(exception) {
        try {
            message = error.message.split('"message":"')[1].split(',"data')[0].replace(/"+$/, "").split('"}')[0];
        }
        catch {
            message = error.message.replace(/"+$/, "");
        }
    }

    if(message.includes("insufficient funds")) {
        message = "Insufficient funds. Check that you have enough funds also for the gas fees."
    }
    else if(message.includes("nonexistent token")) {
        message = "The token ID you entered does not exist."
    }
    else if(message.includes("You are not the owner")) {
        message = "You are not the owner of this token."
    }
    else if(message.includes("Not over")) {
        message = "The game for this token is not over yet."
    }
    else if(message.includes("already used")) {
        message = "You already used this token to claim a prize."
    }
    else if(message.includes("too low")) {
        message = "Not enough points to claim this prize."
    }
    else if(message.includes("too high")) {
        message = "Too many points to claim this prize."
    }

    return message
}

function isMobile() {
    try{ document.createEvent("TouchEvent"); return true; }
    catch(e){ return false; }
}

function cropAddress(address) {
    return address.slice(0, 6) + "..." + address.slice(-4)
}

function applyToElements(className, fx) {
    let elements = [...document.getElementsByClassName(className)];
    elements.forEach((element) => fx(element))
}

async function verifyAndClaimPrize(tokenId, position) {
    try {
        await contractRead.checkEligibility(tokenId, trophyIds[position], {from: address()})
        let tx = await contractWrite.claimPrize(tokenId, trophyIds[position], {from: address()})

        let lastConfirms = -1;
        async function handler(blockNumber) {
            let receipt = await provider.getTransactionReceipt(tx.hash);
            if (receipt == null || receipt.confirmation < lastConfirms) {
                // A re-org; do what you need here
            }
        }
        provider.on("block", handler);


        show(overlayClaimingClass);
        await tx.wait(1)
        // This gets called once there are 3 confirmations
        provider.removeListener("block", handler);
    }
    catch(error) {
        if(typeof handler !=='undefined') {
            provider.removeListener("block", handler);
        }
        throw error
    }
}


async function updatePrizeData() {
    //var rules = ["Minimum 40 PTS to CLAIM", "Minimum 30 PTS to CLAIM", "Minimum 20 PTS to CLAIM", "Exactly 1 PT to CLAIM"]

    var lis = [...document.querySelectorAll(".page > .list-3 > li")]
    for(var position = 0; position < 4; position++) {
        try {
            var data = await contractRead.getPrizeIdData(trophyIds[position])
            var trophyLink = `${openSeaBaseAddress}${trophyIds[position]}`
            lis[position].querySelector(".nfttrophylink").href = trophyLink;
            if(data.winner != "0x0000000000000000000000000000000000000000") {
                lis[position].querySelector(".gameinformation > .claimedlabel").style.display = 'block'
                lis[position].querySelector(".gameinformation > .unclaimedlabel").style.display = 'none'
                lis[position].querySelector(".winneritem").style.display = "inline";
                lis[position].querySelector(".winnerwalletidlink").href = `${openSeaBaseAddress}${data.winningToken.toString()}`;
                lis[position].querySelector(".winnertokentid").innerHTML = `Arcade Glyph #${data.winningToken.toString()}`;
                lis[position].querySelector(`.claimprize${position+1}button`).style.display = "none"
                lis[position].querySelector(".viewonopensea").style.display = "inline"
                lis[position].querySelector(".viewonopensea").href = trophyLink
                lis[position].querySelector(".availabletrophies").innerHTML = "0"
            }
            else {
                lis[position].querySelector(".gameinformation > .claimedlabel").style.display = 'none'
                lis[position].querySelector(".gameinformation > .unclaimedlabel").style.display = 'block'
                lis[position].querySelector(".winneritem").style.display = "none";
                lis[position].querySelector(`.claimprize${position+1}button`).style.display = "block"
                lis[position].querySelector(".viewonopensea").style.display = "none"
                lis[position].querySelector(".availabletrophies").innerHTML = "1"
            }
        }
        catch(error) {
            console.log(error)
        }
    }
}

async function mint(bulk) {

    try {

        let tx;
        if(bulk) {
            tx = await contractWrite.insertNote(game, {value: bulkPrice, from: address()})
        }
        else {
            tx = await contractWrite.insertCoin(game, {value: price, from: address()})
        }

        let lastConfirms = -1;
        async function handler(blockNumber) {
            let receipt = await provider.getTransactionReceipt(tx.hash);
            if (receipt == null || receipt.confirmation < lastConfirms) {
                // A re-org; do what you need here
            }
        }
        provider.on("block", handler);

        show(overlayMintingClass);
        await tx.wait(1)
        // This gets called once there are 3 confirmations
        provider.removeListener("block", handler);
        
    }
    catch(error) {
        if(typeof handler !== 'undefined') {
            provider.removeListener("block", handler);
        }
        throw error;
    }
}

async function listTokens(i) {
    try {
        let token = await contractRead.tokenOfOwnerByIndex(address(), i)
        if(token != 0) {
            return [token].concat(await listTokens(i + 1))
        }

        return []
    }
    catch(error) {
        return []
    }
}

async function loadToken(token, blockNumber) {
    var data = db.getItem("tokenData" + token);
    if(data == null) {
        data = await contractRead.tokenURI(token)
    }

    let json = JSON.parse(data.slice(16))
    let isGameOver = json["attributes"][2]["value"] != "Playing"
    if(isGameOver) {
        db.setItem("tokenData" + token, data)
    }
    let blocksToNext = (256 - (blockNumber - json["attributes"][3]["value"]) % 256)
    let isNewGame = (blockNumber - json["attributes"][3]["value"]) < 256
    let blocksTag = isGameOver ? "NO MORE UPDATES" : `NEXT UDATE IN ${blocksToNext} BLOCKS`
    let gameOverTag = '<span class="gameoverlabel">Game Over</span>'
    let newGameTag = '<span class="newgamelabel">New Game</span>'
    let playingTag = '<span class="ongoinglabel">Ongoing Game</span>'
    let badge = playingTag
    if(isNewGame) {
        badge = newGameTag
    }
    else if(isGameOver) {
        badge = gameOverTag
    }

    var loadedGame = `
    <div class="gamelistitemcontent">
        <div class="gamecontainer list">
        <div class="gameasset">${json["image"].replace(/p1/g, "p1" + token).replace(/stop/g, 'stop' + token)}</div>
        </div>
        <div class="gameinfo w-clearfix">
        <h1 class="heading3">${json["name"]}</h1>
        <div class="gameinformation">${badge}</div>
        <ul role="list" class="list3 w-list-unstyled">
            <li class="listitem w-clearfix">
            <div class="listicon pts"></div>
            <div class="listitemtext">${json["attributes"][0]["value"]} PTS</div>
            </li>
            <li class="listitem w-clearfix">
            <div class="listicon next"></div>
            <div class="listitemtext">${blocksTag}</div>
            </li>
            <li class="listitem w-clearfix">
            <div class="listicon time"></div>
            <div class="listitemtext">PLAYED FOR ${json["attributes"][1]["value"]} MOVES</div>
            </li>
        </ul>
        <div class="buttonscontainer">
            <a href="${openSeaBaseAddress + token}" target="_blank" class="buttonsecondary large opsenseabutton w-button">View on OpenSea</a>
        </div>
        </div>
    </div>
    `

    var loader = document.getElementById(token)
    loader.innerHTML = loadedGame;
    loader.classList.remove("loading")
}

async function updateUserTokens() {
    var ul = document.getElementsByClassName(userTokensClass)[0]
    ul.innerHTML = ""
    var loadingElement = `
    <li id={id} class="gamelistitem loading" style="display: block;">
          <div class="gamelistitemcontent">
            <div class="gamecontainer list">
              <div class="gameasset loading">
                <div class="spinner"></div>
                <div class="loadingtext">Loading asset</div>
              </div>
            </div>
            <div class="gameinfo w-clearfix">
              <h1 class="heading3">Loading Glyph...</h1>
              <div class="gameinformation"><span class="ongoinglabel">Retreiving data</span></div>
              <ul role="list" class="list3 w-list-unstyled">
                <li class="listitem loading short"></li>
                <li class="listitem loading"></li>
                <li class="listitem loading"></li>
              </ul>
            </div>
          </div>
        </li>
    `

    let initialLoaderId = "initialLoaderId"
    ul.innerHTML = ul.innerHTML + loadingElement.replace("{id}", initialLoaderId);
    var allTokens = await listTokens(0);
    var blockNumber = await provider.getBlockNumber();
    
    var initialLoader = document.getElementById(initialLoaderId)
    initialLoader.parentElement.removeChild(initialLoader)
    
    for(var i = allTokens.length - 1; i >= 0; i--) {
        if(allTokens[i] < 9000000) {
            ul.innerHTML = ul.innerHTML + loadingElement.replace("{id}", allTokens[i]);
            loadToken(allTokens[i], blockNumber)
        }
    }
}


async function isMismatch(aProvider) {
    try {
        let networkName = (await aProvider.getNetwork()).name
        return (networkName !== "homestead" && isDomainProd() && !isRCProd())
            || (networkName === "homestead" && (!isDomainProd() || isRCProd()))
    }
    catch(error) {
        return false
    }
}

function showInfo(text) {
    document.querySelector(".infobanner").style.display = "block"
    document.querySelector(".infobannercopy").innerHTML = text
}

// State update
async function networkMatch(networkName) {
    if (networkName === "unknown") {
        document.querySelector(".infobanner").style.display = "none"
    }
    else if(networkName !== "homestead" && isDomainProd() && !isRCProd()) {
        // You are not on the mainnet
        document.querySelector(".infobanner").style.display = "block"
        document.querySelector(".infobannercopy").innerHTML = "Your wallet is not connected to the Mainnet!"
        
    }
    else if (networkName === "homestead" && (!isDomainProd() || isRCProd())) {
        document.querySelector(".infobanner").style.display = "block"
        document.querySelector(".infobannercopy").innerHTML = "Your wallet is connected to the Mainnet but you are using the test version of the website!"
    }
    else {
        document.querySelector(".infobanner").style.display = "none"
    }
}

async function updatePrice() {
    var newPrice = await contractRead.currentPrice(game)
    price = newPrice;

    var newBulkPrice = await contractRead.currentBulkPrice(game)
    bulkPrice = newBulkPrice;
}

function updateAlreadyMinted() {
    contractRead.alreadyMinted(game).then(
        (alreadyMinted) => {
            let amount = alreadyMinted.toNumber();
            applyToElements(alreadyMintedTextClass, (element) => element.innerHTML = amount)
            applyToElements(hpClass, (element) => element.style.width = Math.max(1, (amount / supply * 100)) + "%");
        }
    )
}

function listenToMinting() {
    contractRead.on("Transfer", (to, amount, from) => {
        updateAlreadyMinted();
    });
}

async function updateWallet() {
    const dataUrl = Pixel.dataUrl(address(), 40, 40, {"color": "#000000"});
    applyToElements(avatarClass, (x) => x.style.backgroundImage = "url(" + dataUrl + ")")
    applyToElements(walletClass, (x) => x.innerText = cropAddress(address()))
}

async function setSupply() {
    try {
        supply = (await contractRead.supplyForGame(game)).toNumber();
        applyToElements(totalSupplyTextClass, (element) => element.innerHTML = supply);

    } catch(error) {
        console.log(error)
    }
}
function refreshUI() {
    updateDynamicElements().catch((error) => getMessage(error));
}

function show(modal) {
    applyToElements(overlayClass, (o) => o.style.display = "block");
    applyToElements(modal, (e) => e.style.display = "block");
}

function hide(modal) {
   applyToElements(overlayClass, (o) => o.style.display = "none");
   applyToElements(modal, (e) => e.style.display = "none");
}

async function updateDynamicElements() {    
    if(noWeb3() && !isMobile()) {
        applyToElements(connectButtonClass, (button) => {
            button.style.display = "";
            button.href = "https://metamask.io/download.html"
            button.target = "_blank"
        })
        
        applyToElements(walletButtonClass, (button) => {
            button.style.display = "none";
        })

        applyToElements(mintButtonClass, (button) => {
            button.href = "https://metamask.io/download.html"
            button.target = "_blank"
        })
        

        applyToElements(bulkMintButtonClass, (button) => {
            button.href = "https://metamask.io/download.html"
            button.target = "_blank"
        })
    }
    else if(isMobile()) {
        window.showMobileNotSupported = () => show('modalmobilenotsupported');
        let allButtons = [connectButtonClass, mintButtonClass, bulkMintButtonClass, logoutButtonClass, claimPrize1ButtonClass, claimPrize2ButtonClass, claimPrize3ButtonClass, claimPrize4ButtonClass]
        allButtons.forEach((button) => 
            applyToElements(button, (b) => 
                b.setAttribute("onclick", "showMobileNotSupported();")
            )
        )
    }
    else {
        if(isConnected()) {
            applyToElements(connectButtonClass, (button) => {
                button.style.display = "none";
            });

            applyToElements(walletButtonClass, (button) => {
                button.style.display = "block";
            });
        }
        else {
            applyToElements(connectButtonClass, (button) => {
                button.style.display = "block";
            });

            applyToElements(walletButtonClass, (button) => {
                button.style.display = "none";
            });
        }

        exposeWeb3Functions()
    }

    applyToElements(openseaLinkClass, (a) => {
        if (isDomainStaging() || isRCProd()) {
            a.href = `${testnetCollectionOpenseaURL}${collectionName}`
        }
        else {
            a.href = `${openseaCollectionURL}${collectionName}`
        }
        a.target = "_blank"
    })

    await setSupply();
    updateAlreadyMinted();
    await updatePrice();

    if(window.location.toString().includes("glyph-list")) {
        await updateUserTokens()
    }

    if(window.location.toString().includes("trophies")) {
        await updatePrizeData();
    }
}

// handlers

function setupPage() {
    setupConnections().then(() => {
        refreshUI();
        listenToMinting();
    }).catch((error) => showInfo(getMessage(error)));
}

// Web3 Handling

async function setupConnections() {
    setupEnvironmentVariables()
    setupRpcReadProvider();
    if(!noWeb3()) {
        await setupMetamaskProvider()
    }
}

function setupEnvironmentVariables() {
    if(isDomainStaging() || isRCProd()) {
        contractAddress = rinkebyContractAddress
        openSeaBaseAddress = `https://testnets.opensea.io/assets/${contractAddress}/`
        rpcUrl = rinkebyJSONRpc
    }
    else if(isDomainProd()) {
        contractAddress = mainnetContractAddress
        openSeaBaseAddress = `https://opensea.io/assets/${contractAddress}/`
        rpcUrl = mainnetJSONRpc
    } 
    else {
        contractAddress = devContractAddress
        openSeaBaseAddress = `https://development.opensea.io/assets/${contractAddress}/`
        rpcUrl = devJSONRpc
    }
}

function setupRpcReadProvider() {
    provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    
    contractRead = new ethers.Contract(contractAddress, abi, provider);
}

async function setupMetamaskProvider() {
    var newProvider = new ethers.providers.Web3Provider(window.ethereum);

    await networkMatch((await newProvider.getNetwork()).name)
    if(await isMismatch(newProvider)) {
        return
    }

    provider = newProvider
    contractRead = new ethers.Contract(contractAddress, abi, provider);
    
    var granted = (await isGranted())
    if(granted) {
        if(isConnected()) {
            await refreshWriteConnection();
        }
    }
    else if(isConnected()){
        db.clear()
    }
}

async function refreshWriteConnection() {
    let accounts = await window.ethereum.request({method: "eth_requestAccounts"})
        
    if(accounts[0] != address()) {
        db.clear()
    }
    await provider.getNetwork()
    const signer = await provider.getSigner();
    db.setItem("address", await signer.getAddress());
    contractWrite = new ethers.Contract(contractAddress, abi, signer);
    
    await updateWallet();
}

// Connectivity utilities

async function isGranted() {
    return (await provider.send("wallet_getPermissions")).length > 0
}

function noWeb3() {
    try {
        return window.ethereum === undefined;
    }
    catch (error) {
        return false;
    }
}

function isConnected() {
    return db.getItem("address") != null;
}


// Actions handlers
function connectAndExecute(fx) {
    if(isConnected()) {
        fx()
    }
    else {
        /*if(isMobile()) {
            setupWalletConnectProvider().then(() => {
                fx()
            });
        }
        else {*/
        setupConnections().then(() => 
            refreshWriteConnection().then(
                () => fx()
            ).catch(
                (error) => showInfo(getMessage(error))
            )
        );
        //}
    }
}

function doMintGame(bulk) {
    connectAndExecute(() => {
            mint(bulk).then(
                () => {
                    hide(overlayMintingClass);
                    show(overlayMintingDoneClass);
                }
            ).catch(
                (error) => {
                    hide(overlayMintingClass);
                    hide(overlayMintingDoneClass);
                    show(overlayMintingFailedClass);
                    document.querySelector(`.${overlayMintingFailedClass} .modalinfo`).innerHTML = getMessage(error)
                    
                }
            )
        }
    )
}

function doConnectWallet() {
    /*if(isMobile()) {
        console.log("is mobile")
        setupWalletConnectProvider().then(
            () => refreshUI()
        ).catch(
            (error) => console.log(getMessage(error)) 
        )
    }
    else {*/
        setupConnections().then(() => 
            refreshWriteConnection().then(
                () => refreshUI()
            ).catch(
                (error) => showInfo(getMessage(error))
            )
        );
    //}
}

function doLogout() {
    db.clear();
    console.log("LOCAL ",db.getItem("address"))
    window.location.reload(true);
}

function doClaimPrize() {
    let position = db.getItem("claimPosition")
    let tokenInput = document.getElementsByClassName(prizeInputTokenIdClass)[0].value.split("?")[0].split("/")
    let tokenId = tokenInput[tokenInput.length - 1]

    connectAndExecute(() => {
            hide(prizeInputTokenModalClass);
                    
            verifyAndClaimPrize(tokenId, position).then(
                () => {
                    hide(overlayClaimingClass);
                    show(overlayClaimingDoneClass)
                    refreshUI();
                }
            ).catch(
                (error) => {
                    hide(overlayClaimingClass);
                    document.querySelector(`.${overlayClaimingFailedClass} .modalinfo`).innerHTML = getMessage(error)
                    show(overlayClaimingFailedClass)
                }
            )
        }
    )
}

function doAskPrizeInput(position) {
    show(prizeInputTokenModalClass);
    db.setItem("claimPosition", position);
}


function exposeWeb3Functions() {
    window.connectWallet = doConnectWallet;
    window.mintGame = doMintGame;
    window.claimPrize = doClaimPrize;
    window.logout = doLogout;
    window.claimPrize = doClaimPrize;
    window.askPrizeInput = doAskPrizeInput;
    let prizeButtons = [claimPrize1ButtonClass, claimPrize2ButtonClass, claimPrize3ButtonClass, claimPrize4ButtonClass]
    
    // Set actions
    applyToElements(connectButtonClass, (button) =>{
        button.setAttribute("onclick", "connectWallet();");
    });
    
    applyToElements(mintButtonClass, (button) =>{
        button.setAttribute("onclick", "mintGame(false);");
    });

    applyToElements(bulkMintButtonClass, (button) =>{
        button.setAttribute("onclick", "mintGame(true);");
    });

    applyToElements(logoutButtonClass, (button) =>{
        button.setAttribute("onclick", "logout();");
    });

    for(var i = 0; i < 4; i++) {
        applyToElements(prizeButtons[i], (button) =>{
            button.setAttribute("onclick", `askPrizeInput(${i});`);
        });
    }

    applyToElements(claimPrizeSubmitButtonClass, (button) =>{
        button.setAttribute("onclick", `claimPrize();`);
    });
}


(async () => {
    var location = window.location.toString()
    if(location.includes("glyphs/")) {
        var parts = location.split("/")
        var glyph = parts[parts.length - 1] 
        window.location = "https://opensea.io/assets/0x7eefee2d0b0e23b7ce6b56a9ce9b62a599e6e9da/" + glyph
    }

    if(!noWeb3()) {
        ethereum.on('accountsChanged', function (accounts) {
            setupPage();
        })
        ethereum.on('chainChanged', function (accounts) {
            setupPage();
        })
    }

    setupPage();
})();
